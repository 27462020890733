<template>
    <section class="py-5" style="padding-top: 0rem !important;">
        <div class="container" style="padding-left: 0;padding-right: 0">
            <!-- <iframe style="max-height: calc(100vh);width: <?php echo $jsonGame->data->width; ?>;height: <?php echo $jsonGame->data->height; ?>;" :src="game.url" width="100%" height="900" frameborder="0" scrolling="no"></iframe> -->
             <iframe :class={loaded} ref="frame" :src="url_data"  frameborder="0" scrolling="no"></iframe>
        </div>
    </section>
</template>

<script>
// import { ref } from '@vue/reactivity';
// import { HTTP } from '@/Api/http-common';
import { useRouter, useRoute } from "vue-router";
import { useCookie } from 'vue-cookie-next'
// import { watchEffect } from '@vue/runtime-core';
// import { HTTPSPA } from '@/Api/http-Spay';
// import Loading from '../Spinners/Loading.vue'
import { onMounted, ref } from 'vue';
export default {
   name: 'AppGames',
    // components: {
    //   Loading
    // },
   props: {
        gid: String
    },
   setup() {
      const router = useRouter();
      const route = useRoute();
      const cookie = useCookie();
      // const loaded = ref(false);
      const url_data = ref([]);
      // const frame = ref()
      // const loading = ref(false)

      const getUrl = async () => {
           
            try {
              // setTimeout(() => loading.value = true, 5000);
                url_data.value = `https://www.yallahgame.com/GamesPage/index.php?id=${route.params.gid}`
            } catch (err) {
                console.log(err);
            }
        };

        onMounted(() => {
            getUrl();
            // frame.value.onload = () => {
            //   loaded.value = true
            // }
        });

      const getFeed = (game) => {
          router.push({ name: "GamesDetails", params: { gid: game.gid } });
      };
      return {
        getFeed,
        url_data,
        // frame,
        // loading
        // loaded
      };
    }
}
</script>

<style>
iframe{
  width: 100%;
  height: 90vh;
  overflow:hidden;
}
@media(max-width:1024px){
  iframe{
    max-height: 90vh;
  }
}
@media(max-width:834px){
  iframe{
    max-height: 84vh;
  }
}
@media(max-width:820px){
  iframe{
    max-height: 84vh;
  }
}
@media(max-width:810px){
  iframe{
    max-height: 84vh;
  }
}
@media(max-width:786px){
  iframe{
    max-height: 83vh;
  }
}
@media(max-width:768px){
  iframe{
    max-height: 83vh;
  }
}
@media(max-width:428px){
  iframe{
    max-height: 83vh;
  }
}
@media(max-width:414px){
  iframe{
    max-height: 83vh;
  }
}
@media(max-width:412px){
  iframe{
    max-height: 82vh;
  }
}

@media(max-width:384px){
  iframe{
    max-height: 82vh;
  }
}
@media(max-width:375px){
  iframe{
    max-height: 82vh;
  }
}
@media(max-width:360px){
  iframe{
    max-height: 82vh;
  }
}
</style>